import store from '@/services/store';
import {Prop} from 'vue-property-decorator';
import UtilUI from './util-ui';
import {IKeyCloakUser} from "./user-management-int";

export default class BaseUserLink extends UtilUI {
    

    protected readonly  um_role: string ="um_admin";

    @Prop() protected selectedUser!: IKeyCloakUser;
     
    protected get isHasUmAdminRole(): boolean {
        return store.getters.user.roles.includes(this.um_role);
    }
    
    //при скрытой колнке 'доступные' чтобы колонка 'выбранные' была на всю ширину
    protected get cols(): string{
        return  this.isHasUmAdminRole ? "6":"12";
     }

}