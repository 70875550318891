import {Vue} from 'vue-property-decorator';

export default class UtilUI extends Vue {

    protected makeToast(variant: string, title: string, tostbody: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

}