
















































import { Component,  Watch } from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import '../user-management.scss';
import { IKeyCloakUser, IEbkItem } from '../user-management-int';
import BaseUserLink from '../base-user-link';

@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class FgrUserData extends BaseUserLink {
    private funcGrBase: any = [];
    private funcGrList: any[] = [];
    //@Prop() private selectedUser!: IKeyCloakUser;
    private selectedUserFgrList: IEbkItem[] = [];
    private loading = false;

    @Watch('selectedUser')
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        this.loading = true;
        if (oldValue !== null) {
            this.selectedUserFgrList = [];
            this.funcGrList = [...this.funcGrBase];
            this.funcGrList.sort((a, b) => a.gr - b.gr);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private async loadFuncGroup() {
        try {
            const response = await fetch('/api-py/get-dict-func-childs-by-parent/0');
            this.funcGrBase = await response.json() as IEbkItem;
            this.funcGrList = [...this.funcGrBase];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки функциональных групп', error.toString());
        }
    }

    protected async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserFuncGroup(user);
        if (this.selectedUser !== null) {
            for (const item of this.selectedUserFgrList) {
                for (const it of this.funcGrList) {
                    if (it.gr === item.gr) {
                        const ind = this.funcGrList.indexOf(it);
                        this.funcGrList.splice(ind, 1);
                    }
                }
            }
        }
    }

    removeAllFgr() {
        for (const abpListElement of this.selectedUserFgrList) {
            this.funcGrList.push(abpListElement)
        }
        this.funcGrList.sort((a, b) => a.gr - b.gr);
        this.selectedUserFgrList = []
        this.saveUserFgrData(this.selectedUser);
    }

    addAllFgr() {
        for (const abpListElement of this.funcGrList) {
            if (!this.selectedUserFgrList.find(value => value.id === abpListElement.id)) this.selectedUserFgrList.push(abpListElement)
        }
        this.selectedUserFgrList.sort((a, b) => a.gr - b.gr);
        this.funcGrList = []
        this.saveUserFgrData(this.selectedUser);
    }

    private addFgrToSelectedUser(fgr: IEbkItem, save: boolean) {
        if (this.selectedUserFgrList.indexOf(fgr) < 0) {
            this.selectedUserFgrList.push(fgr);
            const ind = this.funcGrList.indexOf(fgr);
            this.funcGrList.splice(ind, 1);
        }
        this.selectedUserFgrList.sort((a, b) => a.gr - b.gr);
        if (save) { this.saveUserFgrData(this.selectedUser); }
    }

    private removeFgrFromSelectedUser(fgr: IEbkItem, save: boolean) {
        const ind = this.selectedUserFgrList.indexOf(fgr);
        this.selectedUserFgrList.splice(ind, 1);
        this.funcGrList.push(fgr);
        this.funcGrList.sort((a, b) => a.gr - b.gr);
        if (save) { this.saveUserFgrData(this.selectedUser); }
    }

    private async saveUserFgrData(user: IKeyCloakUser) {
        const fgrList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserFgrList) {
            const d = {
                fgr: item.gr
            };
            fgrList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                fgrList: fgrList
            };
            try {
                await fetch('/api-py/save-user-fgr', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveData)
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
            }
        }
    }

    private async loadUserFuncGroup(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.selectedUserFgrList = [];
                const response = await fetch('/api-py/user-fgr/' + user?.id);
                const data = await response.json();
                for (const item of data) {
                    for (const t of this.funcGrList) {
                        if (item.fgr === t.gr) {
                            this.addFgrToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных ФГ : ' + user.id, error.toString());
            }
    }

    public async mounted() {
        await this.loadFuncGroup();
        if (this.selectedUser !== null) {
            this.loadUserFuncGroup(this.selectedUser);
        }
    }
}
