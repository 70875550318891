






































































import {Component, Watch} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import '../user-management.scss';
import { IKeyCloakUser, IEbkItem } from '../user-management-int';
import BaseUserLink from '../base-user-link';

@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class AbpUserData extends BaseUserLink {
    private abpBase: any = [];
    private abpList: any[] = [];
    private selectedUserAbpList: IEbkItem[] = [];
    private loading = false;
    private leftFilter = '';
    private rightFilter = '';

    @Watch('selectedUser')
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        this.loading = true;
        if (oldValue !== null) {
            this.selectedUserAbpList = [];
            this.abpList = [...this.abpBase];
            this.abpList.sort((a, b) => a.abp - b.abp);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private get leftFilterAbpList() {
        const res = [];
        for (const item of this.abpList) {
            if (item.abp.toString().includes(this.leftFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.leftFilter)) {
                res.push(item);
            }
        }
        return res;
    }

    private get rightFilterAbpList() {
        const res = [];
        for (const item of this.selectedUserAbpList) {
            if (item.abp.toString().includes(this.rightFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.rightFilter)) {
                res.push(item);
            }
        }
        return res;
    }

    private async loadFuncGroup() {
        try {
            const response = await fetch('/api-py/get-dict-ved-abp-by-budgetlevel/'+this.userLevelsList);
            this.abpBase = await response.json() as IEbkItem;
            this.abpList = [...this.abpBase];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
        }
    }

    private userLevels: string[] = [];
    get userLevelsList() {
        let result = '['
        for (const lv of this.userLevels) {
            result = result + lv + ','
        }
        if (result.substring(result.length - 1) == ',') {
            result = result.substring(0, result.length-1) + ']'
        }
        if (result = '[') {
            result = '[2,3,4]'
        }
        return result
    }

    private async loadUserLevels(user: IKeyCloakUser) {
        try {
            const response = await fetch('/api-py/get-user-budget-level-only/'+user?.id);
            this.userLevels = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровней пользователя', error.toString());
        }
    }

    public async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserFuncGroup(user);
        if (this.selectedUser !== null) {
            for (const item of this.selectedUserAbpList) {
                for (const it of this.abpList) {
                    if (it.abp === item.abp) {
                        const ind = this.abpList.indexOf(it);
                        this.abpList.splice(ind, 1);
                    }
                }
            }
        }
    }

    removeAllAbp() {
        for (const abpListElement of this.selectedUserAbpList) {
            this.abpList.push(abpListElement)
        }
        this.abpList.sort((a, b) => a.abp - b.abp);
        this.selectedUserAbpList = []
        this.saveUserabpData(this.selectedUser);
    }

    addAllAbp() {
        for (const abpListElement of this.abpList) {
            if (!this.selectedUserAbpList.find(value => value.id === abpListElement.id)) this.selectedUserAbpList.push(abpListElement)
        }
        this.selectedUserAbpList.sort((a, b) => a.abp - b.abp);
        this.abpList = []
        this.saveUserabpData(this.selectedUser);
    }

    private addAbpToSelectedUser(abp: IEbkItem, save: boolean) {
        if (this.selectedUserAbpList.indexOf(abp) < 0) {
            this.selectedUserAbpList.push(abp);
            const ind = this.abpList.indexOf(abp);
            this.abpList.splice(ind, 1);
        }
        this.selectedUserAbpList.sort((a, b) => a.abp - b.abp);
        if (save) {
            this.saveUserabpData(this.selectedUser);
        }
    }

    private removeAbpFromSelectedUser(abp: IEbkItem, save: boolean) {
        const ind = this.selectedUserAbpList.indexOf(abp);
        this.selectedUserAbpList.splice(ind, 1);
        this.abpList.push(abp);
        this.abpList.sort((a, b) => a.abp - b.abp);
        if (save) {
            this.saveUserabpData(this.selectedUser);
        }
    }

    private async saveUserabpData(user: IKeyCloakUser) {
        const abpList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserAbpList) {
            const d = {
                abp: item.abp
            };
            abpList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                abpList: abpList
            };
            try {
                await fetch('/api-py/save-user-abp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveData)
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
            }
        }
    }

    private async loadUserFuncGroup(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.selectedUserAbpList = [];
                const response = await fetch('/api-py/user-abp/' + user?.id);
                const data = await response.json();
                for (const item of data) {
                    for (const t of this.abpList) {
                        if (item.abp === t.abp) {
                            this.addAbpToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных АБП : ' + user.id, error.toString());
            }
    }

    public async mounted() {
        await this.loadUserLevels(this.selectedUser);
        await this.loadFuncGroup();
        if (this.selectedUser !== null) {
            this.loadUserFuncGroup(this.selectedUser);
        }
    }
}
