
































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import '../user-management.scss';
import {IKeyCloakUser, IEbkItem, IRegion, IKgkp} from '../user-management-int';
import BaseUserLink from '../base-user-link';

@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class GuUserData extends BaseUserLink {
    private kgkpBase: IKgkp[] = [];
    private kgkpList: IKgkp[] = [];
    private regionBase: IRegion[] = [];
    private abpBase: IEbkItem[] = [];
    // private guFilteredList: any[] = [];
    @Prop({default: 45}) private oblRegion: number | undefined;
    private selectedUserKgkpList: IKgkp[] = [];
    private loading = false;
    private leftFilter = '';
    private rightFilter = '';
    private filterRegion = null;
    private filterAbp = null;


    @Watch('selectedUser')
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        this.loading = true;
        if (oldValue !== null) {
            this.selectedUserKgkpList = [];
            this.kgkpList = [...this.kgkpBase];
            this.kgkpList.sort((a, b) => (a.bin > b.bin) ? 1 : -1);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private get leftFilterGuList() {
        const res = [];
        for (const item of this.kgkpList) {
            if (item.bin.toString().includes(this.leftFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.leftFilter)) {
                res.push(item);
            }
        }
        return res;
    }


    private get rightFilterGuList() {
        const res = [];
        for (const item of this.selectedUserKgkpList) {
            if (item.bin.toString().includes(this.rightFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.rightFilter)) {
                res.push(item);
            }
        }
        return res;
    }

    private async loadRegions(user: IKeyCloakUser) {
        try {
            const response = await fetch('/api-py/get-user-regions-by-obl/' + this.oblRegion+'/'+user?.id);
            this.regionBase = await response.json() as IRegion[];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    private async loadRegions_old() {
        try {
            const response = await fetch('/api-py/get-regions-by-obl/' + this.oblRegion);
            this.regionBase = await response.json() as IRegion[];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    private userLevels: string[] = [];
    get userLevelsList() {
        let result = '['
        for (const lv of this.userLevels) {
            result = result + lv + ','
        }
        if (result.substring(result.length - 1) == ',') {
            result = result.substring(0, result.length-1) + ']'
        }
        if (result = '[') {
            result = '[2,3,4]'
        }
        return result
    }

    private async loadUserLevels(user: IKeyCloakUser) {
        try {
            const response = await fetch('/api-py/get-user-budget-level-only/'+user?.id);
            this.userLevels = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровней пользователя', error.toString());
        }
    }

    private async loadABP(user: IKeyCloakUser) {
        try {
            const response = await fetch('/api-py/get-dict-ved-abp-by-budgetlevel-user/'+this.userLevelsList+'/'+user?.id);
            this.abpBase = await response.json() as IEbkItem[];
            if (this.abpBase.length>0) {
                const firstAbp : IEbkItem = {
                    id: 0,
                    gr: 0,
                    abp: 0,
                    name_ru: 'Все АБП',
                    name_kk: 'Все АБП',
                    beg_date: '2022-01-01',
                    end_date: '2022-01-01'
                }
                this.abpBase.unshift(firstAbp);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
        }
    }

    private async loadABP_old() {
        try {
            const response = await fetch('/api-py/get-dict-ved-abp-by-budgetlevel/'+this.userLevelsList+'');
            this.abpBase = await response.json() as IEbkItem[];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
        }
    }

    private async loadGuFromBase() {
        try {
            if ((this.filterRegion !== null) && (this.filterAbp !== null) && (this.filterAbp !== 0)) {
                const response = await fetch('/api-py/dict-kgkp-by-region-abp/' + this.filterRegion + '/' + this.filterAbp);
                this.kgkpBase = await response.json() as IKgkp[];
                this.kgkpList = [...this.kgkpBase];
            }
            if ((this.filterRegion !== null) && (this.filterAbp == 0)) {
                const response = await fetch('/api-py/dict-gu-by-region/' + this.filterRegion + '/' + this.userLevels);
                this.kgkpBase = await response.json() as IKgkp[];
                this.kgkpList = [...this.kgkpBase];
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных ГУ', error.toString());
        }
    }

    public async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserFuncGroup(user);
        if (this.selectedUser !== null) {
            // if (this.selectedUser.guList) {
            //     this.selectedUserGuList = [...this.selectedUser.guList];
            for (const item of this.selectedUserKgkpList) {
                for (const it of this.kgkpList) {
                    if (it.bin === item.bin) {
                        const ind = this.kgkpList.indexOf(it);
                        this.kgkpList.splice(ind, 1);
                    }
                }
            }
            // }
        }
    }

    removeAllGu() {
        for (const abpListElement of this.selectedUserKgkpList) {
            this.kgkpList.push(abpListElement)
        }
        this.kgkpList.sort((a, b) => (a.bin > b.bin) ? 1 : -1);
        this.selectedUserKgkpList = []
        this.saveUserguData(this.selectedUser);
    }

    addAllGu() {
        for (const abpListElement of this.kgkpList) {
            if (!this.selectedUserKgkpList.find(value => value.id === abpListElement.id)) this.selectedUserKgkpList.push(abpListElement)
        }
        this.selectedUserKgkpList.sort((a, b) => (a.bin > b.bin) ? 1 : -1);
        this.kgkpList = []
        this.saveUserguData(this.selectedUser);
    }


    private addGuToSelectedUser(gu: IKgkp, save: boolean) {
        let find = false;
        for (const g of this.selectedUserKgkpList) {
            if (g.bin == gu.bin) {
                find = true;
                break;
            }
        }
        if (!find) {
            this.selectedUserKgkpList.push(gu);
            const ind = this.kgkpList.indexOf(gu);
            this.kgkpList.splice(ind, 1);
            this.selectedUserKgkpList.sort((a, b) => (a.bin > b.bin) ? 1 : -1);
            // this.selectedUser.guList = [...this.selectedUserGuList];
        }
        if (save) {
            this.saveUserguData(this.selectedUser);
        }
    }

    private removeGuFromSelectedUser(gu: IKgkp, save: boolean) {
        const ind = this.selectedUserKgkpList.indexOf(gu);
        this.selectedUserKgkpList.splice(ind, 1);
        this.kgkpList.push(gu);
        this.kgkpList.sort((a, b) => (a.bin > b.bin) ? 1 : -1);
        if (save) {
            this.saveUserguData(this.selectedUser);
        }
    }

    private async saveUserguData(user: IKeyCloakUser) {
        const kgkpList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserKgkpList) {
            const d = {
                kgkp: item.bin
            };
            kgkpList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                kgkpList: kgkpList
            };
            try {
                await fetch('/api-py/save-user-kgkp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveData)
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
            }
        }
    }

    private async loadGuLikeCodeName(templ: string) {
        if (templ.length > 4) {
            this.filterRegion = null;
            this.filterAbp = null;
            try {
                const guResponse = await fetch('/api-py/dict-gu-region-like-code-name/' + this.oblRegion + '/' + templ);
                this.kgkpBase = await guResponse.json() as IKgkp[];
                this.kgkpList = [...this.kgkpBase];
            } catch (error) {
                this.makeToast('danger', 'Ошибка поиска по маске : ' + templ, error.toString());
            }
        }
    }


    private async loadGuByCode(code: string) {
        const guResponse = await fetch('/api-py/dict-kgkp-by-code/' + code);
        return await guResponse.json() as IKgkp[];
    }

    private async loadUserFuncGroup(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.selectedUserKgkpList = [];
                const response = await fetch('/api-py/user-kgkp/' + user?.id);
                const data = await response.json();
                for (const item of data) {
                    const guData = await this.loadGuByCode(item.kgkp);
                    for (const t of guData as IKgkp[]) {
                        if (item.kgkp === t.bin) {
                            this.addGuToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных ГУ для : ' + user.id, error.toString());
            }
    }

    public async mounted() {
        await this.loadUserLevels(this.selectedUser);
        await this.loadABP(this.selectedUser);
        await this.loadRegions(this.selectedUser);
        await this.loadGuFromBase();
        if (this.selectedUser !== null) {
            this.loadUserFuncGroup(this.selectedUser);
        }
    }

}
