





































































import {Component, Prop, Watch} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import '../user-management.scss';
import {IKeyCloakUser, IKato} from '../user-management-int';
import BaseUserLink from '../base-user-link';

@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class KatoUserData extends BaseUserLink {
    private katoBase: any = [];
    private katoList: any[] = [];
    // private katoFilteredList: any[] = [];
    @Prop({default: 45}) private oblKato: number | undefined;
    private selectedUserKatoList: IKato[] = [];
    private loading = false;
    private leftFilter = '';
    private rightFilter = '';

    @Watch('selectedUser')
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        this.loading = true;
        if (oldValue !== null) {
            // await this.saveUserkatoData(oldValue);
            // this.selectedUser.katoList = [...this.selectedUserKatoList];
            this.selectedUserKatoList = [];
            this.katoList = [...this.katoBase];
            this.katoList.sort((a, b) => a.code - b.code);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private get leftFilterKatoList() {
        const res = [];
        for (const item of this.katoList) {
            if (item.code.toString().includes(this.leftFilter)) {
                res.push(item);
            } else if (item.name_ru.toUpperCase().toString().includes(this.leftFilter.toUpperCase())) {
                res.push(item);
            }
        }
        return res;
    }

    private get rightFilterKatoList() {
        const res = [];
        for (const item of this.selectedUserKatoList) {
            if (item.code.toString().includes(this.rightFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.rightFilter)) {
                res.push(item);
            }
        }
        return res;
    }

    private async loadFuncGroup() {
        try {
            const response = await fetch('/api-py/get-katos-by-obl/' + this.oblKato);
            this.katoBase = await response.json() as IKato;
            this.katoList = [...this.katoBase];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    public async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserFuncGroup(user);
        if (this.selectedUser !== null) {
            // if (this.selectedUser.katoList) {
            //     this.selectedUserKatoList = [...this.selectedUser.katoList];
            for (const item of this.selectedUserKatoList) {
                for (const it of this.katoList) {
                    if (it.code === item.code) {
                        const ind = this.katoList.indexOf(it);
                        this.katoList.splice(ind, 1);
                    }
                }
            }
            // }
        }
    }
    removeAllKato() {
        for (const katoListElement of this.selectedUserKatoList) {
            this.katoList.push(katoListElement)
        }
        this.katoList.sort((a, b) => a.id - b.id);
        this.selectedUserKatoList = []
        this.saveUserKatoData(this.selectedUser);
    }

    addAllKato() {
        for (const katoListElement of this.katoList) {
            this.selectedUserKatoList.push(katoListElement)
        }
        this.selectedUserKatoList.sort((a, b) => a.id - b.id);
        this.katoList = []
        this.saveUserKatoData(this.selectedUser);
    }
    private addKatoToSelectedUser(kato: IKato, save: boolean) {
        if (this.selectedUserKatoList.indexOf(kato) < 0) {
            this.selectedUserKatoList.push(kato);
            const ind = this.katoList.indexOf(kato);
            this.katoList.splice(ind, 1);
        }
        this.selectedUserKatoList.sort((a, b) => a.id - b.id);
        // this.selectedUser.katoList = [...this.selectedUserKatoList];
        if (save) {
            this.saveUserKatoData(this.selectedUser);
        }
    }

    private removeKatoFromSelectedUser(kato: IKato, save: boolean) {
        const ind = this.selectedUserKatoList.indexOf(kato);
        this.selectedUserKatoList.splice(ind, 1);
        this.katoList.push(kato);
        this.katoList.sort((a, b) => a.id - b.id);
        if (save) {
            this.saveUserKatoData(this.selectedUser);
        }
    }

    private async saveUserKatoData(user: IKeyCloakUser) {
        const katoList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserKatoList) {
            const d = {
                kato: item.code
            };
            katoList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                katoList: katoList
            };
            try {
                await fetch('/api-py/save-user-kato', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveData)
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
            }
        }
    }

    private async loadUserFuncGroup(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.selectedUserKatoList = [];
                // user.katoList = [];
                const response = await fetch('/api-py/user-kato/' + user?.id);
                const data = await response.json();
                for (const item of data) {
                    for (const t of this.katoList) {
                        if (item.kato === t.code) {
                            this.addKatoToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных регионов по : ' + user.id, error.toString());
            }
    }

    public async mounted() {
        await this.loadFuncGroup();
        if (this.selectedUser !== null) {
            this.loadUserFuncGroup(this.selectedUser);
        }
    }
}
